import { useEffect, useState } from "react";
import supabase from "./supabase";
import "./style.css";

function App() {
  const [currentPage, setCurrentPage] = useState("main");
  const [favorites, setFavorites] = useState([]);
  const [closed, setClosed] = useState([]);
  const [snoozed, setSnoozed] = useState([]);

  const navigateTo = (page) => {
    setCurrentPage(page);
  };

  const removeFromFavorites = (suggestion) => {
    setFavorites(favorites.filter((fav) => fav !== suggestion));
  };

  const removeFromClosed = (suggestion) => {
    setClosed(closed.filter((s) => s !== suggestion));
  };

  const removeFromSnoozed = (suggestion) => {
    setSnoozed(snoozed.filter((s) => s !== suggestion));
  };

  const toggleFavorite = (suggestion) => {
    setFavorites((prevFavorites) =>
      prevFavorites.includes(suggestion)
        ? prevFavorites.filter((fav) => fav !== suggestion)
        : [...prevFavorites, suggestion]
    );
  };

  const isFavorited = (suggestion) => favorites.includes(suggestion);

  const addToClosed = (suggestion) => {
    setClosed((prevClosed) => [...prevClosed, suggestion]);
  };

  const addToSnoozed = (suggestion) => {
    setSnoozed((prevSnoozed) => [...prevSnoozed, suggestion]);
    // Remove snoozed suggestion after 30 minutes
    setTimeout(() => {
      setSnoozed((currentSnoozed) =>
        currentSnoozed.filter((s) => s !== suggestion)
      );
    }, 30 * 60 * 1000);
  };

  return (
    <div className="app-box">
      <Header navigateTo={navigateTo} />
      <main className="main">
        {currentPage === "main" && (
          <SuggestionManager
            favorites={favorites}
            toggleFavorite={toggleFavorite}
            isFavorited={isFavorited}
            closed={closed}
            snoozed={snoozed}
            addToClosed={addToClosed}
            addToSnoozed={addToSnoozed}
          />
        )}
        {currentPage === "about" && <AboutPage navigateTo={navigateTo} />}
        {currentPage === "favorites" && (
          <FavoritesPage
            favorites={favorites}
            removeFromFavorites={removeFromFavorites}
            navigateTo={navigateTo}
          />
        )}
        {currentPage === "closed" && (
          <ClosedPage
            closed={closed}
            removeFromClosed={removeFromClosed}
            navigateTo={navigateTo}
          />
        )}
        {currentPage === "snoozed" && (
          <SnoozedPage
            snoozed={snoozed}
            removeFromSnoozed={removeFromSnoozed}
            navigateTo={navigateTo}
          />
        )}
      </main>
    </div>
  );
}

function Header({ navigateTo }) {
  return (
    <header className="header">
      <Menu navigateTo={navigateTo} />
      <img src="logo.png" alt="Witching Hour Logo" />
      <h1>Witching Hour</h1>
    </header>
  );
}

function Menu({ navigateTo }) {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleNavigation = (page) => {
    navigateTo(page);
    setShowDropdown(false); // Close the dropdown after navigation
  };

  return (
    <div className="menu">
      <button onClick={() => setShowDropdown(!showDropdown)}>
        <ion-icon name="menu-outline"></ion-icon>
      </button>

      {showDropdown && (
        <div className="dropdown-menu">
          <button onClick={() => handleNavigation("about")}>How To</button>
          <button onClick={() => handleNavigation("closed")}>
            Removed List
          </button>
          <button onClick={() => handleNavigation("snoozed")}>
            Snoozed List
          </button>
          <button onClick={() => handleNavigation("favorites")}>
            Favorited List
          </button>
        </div>
      )}
    </div>
  );
}

function AboutPage({ navigateTo }) {
  return (
    <div className="about-page">
      <div className="how-to-content">
        <ul>
          <li>
            <ion-icon name="arrow-forward-circle-outline"></ion-icon> Move to
            the next suggestion
          </li>
          <li>
            <ion-icon name="close-outline"></ion-icon> Remove the current
            suggestion from rotation
          </li>
          <li>
            <ion-icon name="timer-outline"></ion-icon> Don't show the suggestion
            for 30 minutes
          </li>
          <li>
            <ion-icon name="heart-outline"></ion-icon>
            Add or remove the suggestion from your favorites
          </li>
        </ul>
      </div>
      <div className="buttons">
        <button onClick={() => navigateTo("main")}>
          <ion-icon name="arrow-back-outline"></ion-icon>
          Back to Main App
        </button>
      </div>
    </div>
  );
}

function SuggestionManager({
  favorites,
  toggleFavorite,
  isFavorited,
  closed,
  snoozed,
  addToClosed,
  addToSnoozed,
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [currentSuggestion, setCurrentSuggestion] = useState(null);

  useEffect(() => {
    async function getSuggestions() {
      let { data: suggestions, error } = await supabase
        .from("suggestions")
        .select("id, text");
      if (!error) {
        setSuggestions(suggestions);
        const availableSuggestions = suggestions.filter(
          (s) => !closed.includes(s.text) && !snoozed.includes(s.text)
        );

        if (availableSuggestions.length > 0) {
          const randomIndex = Math.floor(
            Math.random() * availableSuggestions.length
          );
          setCurrentSuggestion(availableSuggestions[randomIndex]?.text);
        } else {
          setCurrentSuggestion(
            "You've gone through all suggestions. Check your favorites or reset lists to start over!"
          ); // No available suggestions
        }
      }
    }
    getSuggestions();
  }, []);

  const nextSuggestion = () => {
    const availableSuggestions = suggestions.filter(
      (s) =>
        !closed.includes(s.text) &&
        !snoozed.includes(s.text) &&
        s.text !== currentSuggestion
    );
    if (availableSuggestions.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * availableSuggestions.length
      );
      setCurrentSuggestion(availableSuggestions[randomIndex]?.text);
    } else {
      setCurrentSuggestion(
        "You've gone through all suggestions. Check your favorites or reset lists to start over!"
      ); // No available suggestions
    }
  };

  const closeSuggestion = (suggestion) => {
    addToClosed(suggestion);
    nextSuggestion();
  };

  const snoozeSuggestion = (suggestion) => {
    addToSnoozed(suggestion);
    nextSuggestion();
  };
  return (
    <>
      <Suggestion currentSuggestion={currentSuggestion} />
      <ActionButtons
        currentSuggestion={currentSuggestion}
        nextSuggestion={nextSuggestion}
        closeSuggestion={closeSuggestion}
        snoozeSuggestion={snoozeSuggestion}
        toggleFavorite={toggleFavorite}
        isFavorited={isFavorited}
      />
    </>
  );
}

function Suggestion({ currentSuggestion }) {
  return <section className="suggestion">{currentSuggestion}</section>;
}

function ActionButtons({
  currentSuggestion,
  closeSuggestion,
  snoozeSuggestion,
  toggleFavorite,
  isFavorited,
  nextSuggestion,
}) {
  return (
    <div className="buttons">
      <button id="next-btn" onClick={nextSuggestion}>
        <ion-icon name="arrow-forward-circle-outline"></ion-icon>
      </button>
      <div className="buttons-container">
        <button onClick={() => closeSuggestion(currentSuggestion)}>
          <ion-icon name="close-outline"></ion-icon>
        </button>
        <button onClick={() => snoozeSuggestion(currentSuggestion)}>
          <ion-icon name="timer-outline"></ion-icon>
        </button>
        <button
          id="fav-btn"
          onClick={() => toggleFavorite(currentSuggestion)}
          style={{ color: isFavorited(currentSuggestion) ? "red" : "white" }}
        >
          <ion-icon name="heart-outline"></ion-icon>
        </button>
      </div>
    </div>
  );
}

function FavoritesPage({ favorites, removeFromFavorites, navigateTo }) {
  return (
    <div className="favorites-page">
      <div className="favorites-content">
        <h2>Favorited Suggestions</h2>
        <ul>
          {favorites.length > 0 ? (
            favorites.map((suggestion, index) => (
              <li key={index}>
                {suggestion}
                <button onClick={() => removeFromFavorites(suggestion)}>
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </li>
            ))
          ) : (
            <li>No favorites yet.</li>
          )}
        </ul>
      </div>
      <div className="buttons">
        <button onClick={() => navigateTo("main")}>
          <ion-icon name="arrow-back-outline"></ion-icon>
          Back to Main App
        </button>
      </div>
    </div>
  );
}

function ClosedPage({ closed, removeFromClosed, navigateTo }) {
  return (
    <div className="closed-page">
      <div className="closed-content">
        <h2>Removed Suggestions</h2>
        <ul>
          {closed.length > 0 ? (
            closed.map((suggestion, index) => (
              <li key={index}>
                {suggestion}
                <button onClick={() => removeFromClosed(suggestion)}>
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </li>
            ))
          ) : (
            <li>No removed suggestions.</li>
          )}
        </ul>
      </div>
      <div className="buttons">
        <button onClick={() => navigateTo("main")}>
          <ion-icon name="arrow-back-outline"></ion-icon> Back to Main App
        </button>
      </div>
    </div>
  );
}

function SnoozedPage({ snoozed, removeFromSnoozed, navigateTo }) {
  return (
    <div className="snoozed-page">
      <div className="snoozed-content">
        <h2>Snoozed Suggestions</h2>
        <ul>
          {snoozed.length > 0 ? (
            snoozed.map((suggestion, index) => (
              <li key={index}>
                {suggestion}
                <button onClick={() => removeFromSnoozed(suggestion)}>
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </li>
            ))
          ) : (
            <li>No snoozed suggestions.</li>
          )}
        </ul>
      </div>
      <div className="buttons">
        <button onClick={() => navigateTo("main")}>
          <ion-icon name="arrow-back-outline"></ion-icon> Back to Main App
        </button>
      </div>
    </div>
  );
}

export default App;
